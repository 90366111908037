import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'components/icon';

const themes = {
  green: 'green',
  greenBordered: 'green-bordered',
  slate: 'slate',
  yellowBordered: 'yellow-bordered',
  slateBg: 'slate-bg',
  orange: 'orange',
  transparentWithGreenBorder: 'transparent-with-green-border',
  vipps: 'vipps',
  close: 'close',
  toggle: 'toggle',
  add: 'add',
  warningbanner: 'warningbanner',
  black: 'black'
};

const alignments = {
  left: 'left',
  right: 'right',
  center: 'center',
  fullFidth: 'full-width'
};

const Button = ({
  text,
  children,
  className,
  disabled,
  href,
  target,
  title,
  onClick,
  type,
  value,
  theme,
  housingProjectButtonTheme,
  housingProjectBaseTheme,
  housingProjectColorTheme,
  alignment,
  iconName,
  loading,
  success,
  isExternalLink,
  isDownload,
  iconIsAfterText
}) => {
  const classNames = cn('button', className, {
    [`button_theme--${theme}`]: theme && !housingProjectButtonTheme,
    [`button_alignment--${alignment}`]: alignment,
    'button--with-icon': iconName,
    'button--has-spinner': loading || success,
    'button_theme--dark-primary':
      housingProjectBaseTheme === 'dark' &&
      housingProjectButtonTheme === 'primary',
    'button_theme--dark-primary-green':
      housingProjectBaseTheme === 'dark' &&
      housingProjectButtonTheme === 'primary' &&
      housingProjectColorTheme === 'green',
    'button_theme--dark-secondary':
      housingProjectBaseTheme === 'dark' &&
      housingProjectButtonTheme === 'secondary',
    'button_theme--light-primary':
      housingProjectBaseTheme === 'light' &&
      housingProjectButtonTheme === 'primary',
    'button_theme--light-secondary':
      housingProjectBaseTheme === 'light' &&
      housingProjectButtonTheme === 'secondary'
  });

  let Component = href ? 'a' : 'button';

  return (
    <Component
      className={classNames}
      disabled={disabled}
      href={href}
      onClick={onClick}
      target={target}
      title={title}
      type={type}
      value={value}
      download={href && isDownload}
    >
      {iconName && !iconIsAfterText && (
        <Icon
          className={cn('button__icon', {
            'button__icon--check-circle': iconName === 'check-circle',
            'button__icon--vipps': iconName === 'vipps'
          })}
          name={iconName}
        />
      )}
      {loading && <div className="button__spinner"></div>}
      {success && (
        <Icon
          className="button__checkmark"
          name="check-circle-for-animation"
        ></Icon>
      )}
      {theme === themes.add && <Icon name="plus-small"></Icon>}
      <span className="button__text">{children || text}</span>
      {isExternalLink && (
        <Icon
          className={cn('button__external-link', {
            [`button__external-link--${theme}`]: theme
          })}
          name="external-link-bold"
        ></Icon>
      )}
      {iconName && iconIsAfterText && (
        <Icon
          className={cn('button__icon', {
            'button__icon--right': iconIsAfterText
          })}
          name={iconName}
        />
      )}
    </Component>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  target: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  value: PropTypes.string,
  iconName: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(themes)),
  alignment: PropTypes.oneOf(Object.values(alignments)),
  loading: PropTypes.bool,
  success: PropTypes.bool,
  isExternalLink: PropTypes.bool,
  housingProjectButtonTheme: PropTypes.string,
  housingProjectBaseTheme: PropTypes.string,
  housingProjectColorTheme: PropTypes.string,
  isDownload: PropTypes.bool,
  iconIsAfterText: PropTypes.bool
};

Button.defaultProps = {
  alignment: 'full-width',
  theme: 'green'
};

Button.propTypesMeta = {
  theme: 'exclude',
  alignment: 'exclude',
  onClick: 'exclude',
  className: 'exclude',
  iconName: 'exclude',
  loading: 'exclude',
  success: 'exclude',
  housingProjectBaseTheme: 'exclude',
  housingProjectButtonTheme: 'exclude',
  housingProjectColorTheme: 'exclude'
};

Button.themes = themes;
Button.alignments = alignments;

export default Button;
