import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import useGaTracking from 'hooks/use-ga-tracking';
import Heading from 'components/heading';
import { cookieConsentFor } from 'js/cookie-consent';
import { getUTMParameters } from 'js/utm-helper';

const CognitoForm = ({ dataKey, dataForm, title }) => {
  const cognitoScriptSrc = 'https://www.cognitoforms.com/f/seamless.js';
  const formRef = useRef(null);

  useEffect(() => {
    if (typeof document === 'undefined' || typeof window === 'undefined') {
      return;
    }
    const UTMParameters = getUTMParameters();
    const prefillForm = () => {
      if (window.Cognito && typeof Cognito.prefill === 'function') {
        Cognito.prefill({ UTMParameters: UTMParameters });
      }
    };

    const script = document.createElement('script');
    script.setAttribute('src', cognitoScriptSrc);
    script.setAttribute('data-key', dataKey);
    script.setAttribute('data-form', dataForm);
    script.setAttribute('type', 'text/javascript');
    script.async = true;
    script.onload = prefillForm;

    if (formRef.current) {
      formRef.current.appendChild(script);
    }

    const setupEventHandlers = () => {
      if (window.Cognito) {
        window.Cognito.on('beforeSubmit', event => {
          const { entry, hasErrors } = event.data;

          if (hasErrors) return;

          if (cookieConsentFor('analytics_storage') && window.dataLayer) {
            const personIdentificators = {
              Name: entry?.Name,
              Email: entry?.Email,
              PhoneNumber: entry?.PhoneNumber
            };

            window.dataLayer.push({
              event: 'formSubmit',
              formTitle: title,
              personIdentificators: personIdentificators,
              UTMParameters: UTMParameters
            });

            useGaTracking('CognitoForm', 'Form Submit', title);
          }
        });
      }
    };

    const observer = new MutationObserver(() => {
      if (window.Cognito) {
        setupEventHandlers();
        observer.disconnect();
      }
    });

    observer.observe(formRef.current, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
      if (formRef.current?.contains(script)) {
        formRef.current.removeChild(script);
      }
    };
  }, [dataKey, dataForm, title]);

  return (
    <div className="cognito-form">
      <Heading
        level={2}
        theme={Heading.themes.h2}
        className="cognito-form__title"
      >
        {title}
      </Heading>
      <div ref={formRef} className="cognito-form__container"></div>
    </div>
  );
};

CognitoForm.propTypes = {
  dataKey: PropTypes.string.isRequired,
  dataForm: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default CognitoForm;
