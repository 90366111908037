import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Layout from 'components/layout';
import ComponentList from 'components/component-list';
import HousingProjectHeader from 'components/housing-project-header';
import HousingProjectInformation from 'components/housing-project-information';
import HousingProjectMenu from 'components/housing-project-menu';
import ComponentListItem from 'components/component-list-item';
import RelatedArticlesCarousel from 'components/related-articles-carousel';

const baseThemes = {
  dark: 'dark',
  light: 'light'
};

const colorThemes = {
  green: 'green'
};

const HousingProjectFrontpage = ({
  layout,
  blocks,
  housingProjectHeader,
  information,
  baseTheme,
  colorTheme,
  menu
}) => {
  const header = (
    <HousingProjectHeader {...housingProjectHeader} theme={baseTheme} />
  );

  return (
    <div
      className={cn('housing-project-frontpage', {
        [`housing-project-frontpage--${baseTheme}`]: baseTheme
      })}
    >
      <Layout
        fullWidthChildren={header}
        theme={Layout.themes.housingProject}
        {...layout}
      >
        {menu && <HousingProjectMenu {...menu} theme={baseTheme} />}
        {information && (
          <HousingProjectInformation
            baseTheme={baseTheme}
            colorTheme={colorTheme}
            {...information}
            headingLevel={housingProjectHeader.title.text ? 2 : 1}
          />
        )}

        {blocks && (
          <ComponentList
            additionalComponentProps={{
              RelatedArticlesCarousel: {
                theme:
                  baseTheme === 'dark'
                    ? RelatedArticlesCarousel.themes.dark
                    : RelatedArticlesCarousel.themes.light
              },
              HousingProjectRichTextBlock: {
                theme:
                  baseTheme === 'dark' && !colorTheme
                    ? 'dark'
                    : baseTheme === 'light' && !colorTheme
                      ? 'light'
                      : baseTheme === 'dark' && colorTheme === 'green'
                        ? 'green'
                        : null
              },
              HousingProjectMapBlock: {
                theme: baseTheme === 'dark' ? 'dark' : 'light'
              },
              HousingProjectContactForm: {
                theme:
                  baseTheme === 'dark' && !colorTheme
                    ? 'dark'
                    : baseTheme === 'light' && !colorTheme
                      ? 'light'
                      : baseTheme === 'dark' && colorTheme === 'green'
                        ? 'green'
                        : null
              },
              HousingProjectIframeBlock: {
                theme: baseTheme === 'dark' ? 'dark' : 'light'
              },
              HousingProjectListBlock: {
                theme:
                  baseTheme === 'dark' && !colorTheme
                    ? 'dark'
                    : baseTheme === 'light' && !colorTheme
                      ? 'light'
                      : baseTheme === 'dark' && colorTheme === 'green'
                        ? 'green'
                        : null
              },
              HousingProjectContactBlock: {
                theme:
                  baseTheme === 'dark' && !colorTheme
                    ? 'dark'
                    : baseTheme === 'light' && !colorTheme
                      ? 'light'
                      : baseTheme === 'dark' && colorTheme === 'green'
                        ? 'green'
                        : null
              },
              HousingProjectPromoBlock: {
                theme: baseTheme === 'dark' ? 'dark' : 'light'
              },
              HousingProjectImageWithText: {
                theme:
                  baseTheme === 'dark' && !colorTheme
                    ? 'dark'
                    : baseTheme === 'light' && !colorTheme
                      ? 'light'
                      : baseTheme === 'dark' && colorTheme === 'green'
                        ? 'green'
                        : null
              },
              HousingProjectScrollingImages: {
                theme:
                  baseTheme === 'dark' && !colorTheme
                    ? 'dark'
                    : baseTheme === 'light' && !colorTheme
                      ? 'light'
                      : baseTheme === 'dark' && colorTheme === 'green'
                        ? 'green'
                        : null
              },
              HousingProjectFaqBlock: {
                theme: baseTheme === 'dark' ? 'dark' : 'light'
              },
              HousingProjectTeaserBlock: {
                theme:
                  baseTheme === 'dark' && !colorTheme
                    ? 'dark'
                    : baseTheme === 'light' && !colorTheme
                      ? 'light'
                      : baseTheme === 'dark' && colorTheme === 'green'
                        ? 'green'
                        : null
              },
              HousingProjectMediaBlock: {
                theme:
                  baseTheme === 'dark' && !colorTheme
                    ? 'dark'
                    : baseTheme === 'light' && !colorTheme
                      ? 'light'
                      : baseTheme === 'dark' && colorTheme === 'green'
                        ? 'green'
                        : null
              },
              HousingProjectListWithIconsBlock: {
                theme:
                  baseTheme === 'dark' && !colorTheme
                    ? 'dark'
                    : baseTheme === 'light' && !colorTheme
                      ? 'light'
                      : baseTheme === 'dark' && colorTheme === 'green'
                        ? 'green'
                        : null
              },
              HousingProjectDocuments: {
                theme:
                  baseTheme === 'dark' && !colorTheme
                    ? 'dark'
                    : baseTheme === 'light' && !colorTheme
                      ? 'light'
                      : baseTheme === 'dark' && colorTheme === 'green'
                        ? 'green'
                        : null
              }
            }}
            wrapperElementProps={{
              RelatedArticlesCarousel: {
                themes: [
                  baseTheme === 'dark'
                    ? ComponentListItem.themes.greyBackground
                    : ComponentListItem.themes.whiteBackground,
                  ComponentListItem.themes.noPadding
                ]
              },
              HousingProjectRichTextBlock: {
                themes: [
                  baseTheme === 'dark'
                    ? ComponentListItem.themes.greyBackground
                    : ComponentListItem.themes.whiteBackground,
                  ComponentListItem.themes.wide
                ]
              },
              HousingProjectContactForm: {
                themes: [
                  baseTheme === 'dark'
                    ? ComponentListItem.themes.greyBackground
                    : ComponentListItem.themes.beigeBackground,
                  ComponentListItem.themes.wide
                ]
              },
              HousingProjectListBlock: {
                themes: [
                  baseTheme === 'dark'
                    ? ComponentListItem.themes.blackBackground
                    : ComponentListItem.themes.whiteBackground,
                  ComponentListItem.themes.wide
                ]
              },
              HousingProjectContactBlock: {
                themes: [
                  baseTheme === 'dark'
                    ? ComponentListItem.themes.greyBackground
                    : ComponentListItem.themes.beigeBackground,
                  ComponentListItem.themes.wide
                ]
              },
              HousingProjectIframeBlock: {
                themes: [
                  baseTheme === 'dark'
                    ? ComponentListItem.themes.greyBackground
                    : ComponentListItem.themes.whiteBackground,
                  ComponentListItem.themes.wide
                ]
              },
              HousingProjectMapBlock: {
                themes: [
                  baseTheme === 'dark'
                    ? ComponentListItem.themes.greyBackground
                    : ComponentListItem.themes.whiteBackground,
                  ComponentListItem.themes.wide
                ]
              },
              HousingProjectPromoBlock: {
                themes: [
                  baseTheme === 'dark'
                    ? ComponentListItem.themes.greyBackground
                    : ComponentListItem.themes.whiteBackground,
                  ComponentListItem.themes.wide
                ]
              },
              HousingProjectImageWithText: {
                themes: [
                  baseTheme === 'dark'
                    ? ComponentListItem.themes.greyBackground
                    : ComponentListItem.themes.whiteBackground,
                  ComponentListItem.themes.wide
                ]
              },
              HousingProjectScrollingImages: {
                themes: [
                  baseTheme === 'dark'
                    ? ComponentListItem.themes.blackBackground
                    : ComponentListItem.themes.beigeBackground,
                  ComponentListItem.themes.wide,
                  ComponentListItem.themes.noPadding
                ]
              },
              HousingProjectFaqBlock: {
                themes: [
                  baseTheme === 'dark'
                    ? ComponentListItem.themes.greyBackground
                    : ComponentListItem.themes.whiteBackground,
                  ComponentListItem.themes.wide
                ]
              },
              HousingProjectDocuments: {
                themes: [
                  baseTheme === 'dark'
                    ? ComponentListItem.themes.greyBackground
                    : ComponentListItem.themes.whiteBackground,
                  ComponentListItem.themes.wide
                ]
              },
              HousingProjectTeaserBlock: {
                themes: [
                  baseTheme === 'dark'
                    ? ComponentListItem.themes.greyBackground
                    : ComponentListItem.themes.beigeBackground,
                  ComponentListItem.themes.wide
                ]
              },
              HousingProjectMediaBlock: {
                themes: [
                  ComponentListItem.themes.transparentBackground,
                  ComponentListItem.themes.wide,
                  ComponentListItem.themes.noPadding
                ]
              },
              HousingProjectListWithIconsBlock: {
                themes: [
                  baseTheme === 'dark'
                    ? ComponentListItem.themes.greyBackground
                    : ComponentListItem.themes.beigeBackground,
                  ComponentListItem.themes.wide
                ]
              }
            }}
            {...blocks}
          />
        )}
      </Layout>
    </div>
  );
};

HousingProjectFrontpage.propTypes = {
  blocks: PropTypes.exact(ComponentList.propTypes),
  housingProjectHeader: PropTypes.exact(HousingProjectHeader.propTypes),
  layout: PropTypes.exact(Layout.propTypes),
  information: PropTypes.exact(HousingProjectInformation.propTypes),
  baseTheme: PropTypes.oneOf(Object.values(baseThemes)),
  colorTheme: PropTypes.oneOf(Object.values(colorThemes)),
  menu: PropTypes.exact(HousingProjectMenu.propTypes)
};

HousingProjectFrontpage.baseThemes = baseThemes;
HousingProjectFrontpage.colorThemes = colorThemes;

HousingProjectFrontpage.defaultProps = {
  baseTheme: 'dark'
};

export default HousingProjectFrontpage;
