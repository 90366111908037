import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Heading from 'components/heading';
import Image from 'components/image';
import Link from 'components/link';
import Text from 'components/text';
import Expander from 'components/expander';

const themes = {};

const ContactCard = ({
  image,
  jobTitle,
  name,
  description,
  phone,
  email,
  hasExpandableContent,
  hasGrayScaleImages,
  themes
}) => {
  return (
    <div
      className={cn(
        'contact-card',
        themes.map(theme => `contact-card--${theme}`)
      )}
    >
      {image && (
        <div
          className={cn('contact-card__image', {
            'contact-card__image--grayscale': hasGrayScaleImages
          })}
        >
          <Image theme={Image.themes.background} {...image} />
        </div>
      )}
      <div className="contact-card__text">
        {jobTitle && <p className="contact-card__job-title">{jobTitle}</p>}

        {description ? (
          <div className="contact-card__main-text">
            {hasExpandableContent && name ? (
              <Expander
                title={name}
                theme={Expander.themes.contact}
                headingLevel={3}
              >
                {description && (
                  <Text theme={Text.themes.ingressSmall}>{description}</Text>
                )}
              </Expander>
            ) : (
              <>
                {name && (
                  <Heading
                    className="contact-card__title"
                    level={3}
                    theme={Heading.themes.h3Portal}
                  >
                    {name}
                  </Heading>
                )}
                <div className="contact-card__description">
                  <Text theme={Text.themes.ingressSmall}>{description}</Text>
                </div>
              </>
            )}
          </div>
        ) : (
          name && (
            <Heading
              className="contact-card__title"
              theme={Heading.themes.h3Portal}
              level={3}
            >
              {name}
            </Heading>
          )
        )}
      </div>
      <div className="contact-card__links">
        {phone?.text && (
          <Link
            {...phone}
            className="contact-card__link"
            iconName="phone"
            iconIsAfterText={false}
            showIcon={true}
            theme={Link.themes.contactCard}
            animation={Link.animation.shrink}
          />
        )}
        {email?.text && (
          <Link
            {...email}
            className="contact-card__link"
            iconName="mail"
            iconIsAfterText={false}
            showIcon={true}
            theme={Link.themes.contactCard}
            animation={Link.animation.shrink}
          />
        )}
      </div>
    </div>
  );
};

ContactCard.propTypes = {
  image: PropTypes.exact(Image.propTypes),
  jobTitle: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  phone: PropTypes.exact(Link.propTypes),
  email: PropTypes.exact(Link.propTypes),
  hasExpandableContent: PropTypes.bool,
  hasGrayScaleImages: PropTypes.bool,
  themes: PropTypes.arrayOf(PropTypes.oneOf(Object.values(themes)))
};

ContactCard.propTypesMeta = {
  themes: 'exclude'
};

ContactCard.defaultProps = {
  themes: []
};

ContactCard.themes = themes;

export default ContactCard;
