import React from 'react';
import PropTypes from 'prop-types';

import Image from 'components/image';
import Heading from 'components/heading';
import ContentWrapper from 'components/content-wrapper';

const ListWithIconsBlock = ({ title, items }) => {
  const allHasImages = items.length === items.filter(item => item.image).length;

  return (
    <div className="list-with-icons-block">
      <div className="list-with-icons-block__wrapper">
        <ContentWrapper>
          {title && (
            <div className="list-with-icons-block__title">
              <Heading level={2} theme={Heading.themes.h2}>
                {title}
              </Heading>
            </div>
          )}
          {items && !!items.length && (
            <ul className="list-with-icons-block__items">
              {items.map(item => (
                <li className="list-with-icons-block__item" key={item.title}>
                  {item.image && allHasImages && (
                    <div className="list-with-icons-block__item-image">
                      <Image {...item.image} />
                    </div>
                  )}
                  <div className="list-with-icons-block__item-text-content">
                    {item.title && (
                      <div className="list-with-icons-block__item-title">
                        <Heading
                          level={title ? 3 : 2}
                          theme={Heading.themes.inherit}
                        >
                          {item.title}
                        </Heading>
                      </div>
                    )}
                    {item.text && (
                      <div className="list-with-icons-block__item-text">
                        {item.text}
                      </div>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          )}
        </ContentWrapper>
      </div>
    </div>
  );
};

ListWithIconsBlock.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      image: PropTypes.exact(Image.propTypes)
    })
  )
};

export default ListWithIconsBlock;
