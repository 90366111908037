import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Expander from 'components/expander';
import RichText from 'components/rich-text';
import Heading from 'components/heading';
import Button from 'components/button'

const themes = {
  narrow: 'narrow'
};

const colors = {
  beige: 'beige',
  noColor: 'noColor'
}

const ExpanderList = ({ title, content, hideText, showText, theme, color }) => {
  var listTitle = title;
  return (
    <div
      className={cn('expander-list', { [`expander-list--${theme}`]: theme }, { [`expander-list--${color}`]: color })}
    >
      {title && (
        <Heading className="expander-list__title" theme={Heading.themes.h2}>
          {title}
        </Heading>
      )}
      {content.map(({ richText, title, text, button }) => (
        <Expander
          key={title}
          hideText={hideText}
          showText={showText}
          title={title}
          color={color}
          headingLevel={listTitle ? 3 : 2}
        >
          <div className="expander-list__item-text">
            {text && <p>{text}</p>}
            {richText && (
              <RichText themes={[RichText.themes.expanderList]} {...richText} />
            )}
            {button && (
              <Button
                {...button}
                theme={Button.themes.black}
                alignment={Button.alignments.left}
              />
            )}
          </div>
        </Expander>
      ))}
    </div>
  );
};

ExpanderList.propTypes = {
  title: PropTypes.string,
  content: PropTypes.arrayOf(
    PropTypes.exact({
      richText: PropTypes.exact(RichText.propTypes),
      title: PropTypes.string,
      text: PropTypes.string,
      button: PropTypes.exact(Button.propTypes)
    })
  ),
  hideText: PropTypes.string.isRequired,
  showText: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(Object.values(themes)),
  color: PropTypes.oneOf(Object.values(colors))
};

ExpanderList.propTypesMeta = {
  theme: 'exclude'
};

ExpanderList.defaultProps = {
  content: []
};

ExpanderList.themes = themes;

export default ExpanderList;
