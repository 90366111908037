export function cookieConsentFor(name) {
  // return true; // disable cookie consent for testing

  if (typeof window !== 'undefined' && window.dataLayer) {
    const consentEvents = window.dataLayer.filter(
      event =>
        event[0] === 'consent' &&
        event[1] === 'update' &&
        event[2]?.[name] === 'granted'
    );
    return consentEvents?.length > 0;
  }
  return false;
}
