import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Collapse from 'react-tiny-collapse';
import Heading from 'components/heading';
import Button from 'components/button';


const themes = {
  contact: 'contact'
};

const colors = {
  beige: 'beige',
  noColor: 'noColor'
}

const Expander = ({
  children,
  hideText,
  showText,
  title,
  className,
  isExpanded,
  hasQuestionmarkButton,
  onToggle = () => { },
  icons,
  hasNoContent = false,
  theme,
  color,
  button,
  headingLevel = 2
}) => {
  const [isOpen, setIsOpen] = React.useState(
    isExpanded !== undefined ? isExpanded : false
  );

  const toggle = () => {
    isExpanded !== undefined && onToggle(!isOpen);
    setIsOpen(!isOpen);
  };

  React.useEffect(() => {
    isExpanded !== undefined && setIsOpen(isExpanded);
  }, [isExpanded]);

  return (
    <div
      className={cn('expander', className, {
        'expander--is-active': isOpen,
        'expander--with-icons': icons,
        [`expander--${themes[theme]}`]: themes[theme],
        [`expander--${colors[color]}`]: colors[color],
      })}
    >
      {/* NOTE: The below eslint rule is disabled because the button has a click handler for keyboard users */}
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className={cn('expander__header', {
          'expander__header--has-questionmark': hasQuestionmarkButton
        })}
        onClick={!hasNoContent ? toggle : null}
      >
        <Heading
          className={cn('expander__title', {
            'expander__title--active': isOpen && !hasNoContent,
            'expander__title--no-content': hasNoContent
          })}
          theme={Heading.themes.h2}
          level={headingLevel}
        >
          <span className="expander__title-text">{title}</span>
        </Heading>
        {icons && <div className="expander__header-icons">{icons}</div>}
        {!hasNoContent && (
          <button
            aria-label={isOpen ? hideText : showText}
            aria-expanded={isOpen}
            className={cn('expander__button', {
              'expander__button--active': isOpen,
              'expander__button--has-questionmark': hasQuestionmarkButton
            })}
            onClick={e => {
              e.stopPropagation(); // NOTE: To allow for having an onClick on the 'header' without the 'toggle' function being called twice
              toggle();
            }}
            type="button"
          >
            {hasQuestionmarkButton ? (
              <div className="expander__questionmark">?</div>
            ) : null}
          </button>
        )}
      </div>

      <Collapse className="expander__content" isOpen={isOpen}>
        <div className="expander__content-wrapper">{children}</div>
      </Collapse>
    </div>
  );
};

Expander.propTypes = {
  children: PropTypes.node,
  hideText: PropTypes.string.isRequired,
  showText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool,
  className: PropTypes.string,
  onToggle: PropTypes.func,
  icons: PropTypes.node,
  hasQuestionmarkButton: PropTypes.bool,
  hasNoContent: PropTypes.bool,
  theme: PropTypes.oneOf(Object.keys(themes)),
  color: PropTypes.oneOf(Object.keys(colors)),
  button: PropTypes.exact(Button.propTypes),
  headingLevel: PropTypes.number
};

Expander.propTypesMeta = {
  className: 'exclude',
  isExpanded: 'exclude',
  onToggle: 'exclude',
  icons: 'exclude',
  hasQuestionmarkButton: 'exclude',
  hasNoContent: 'exclude',
  theme: 'exclude',
  color: 'exclude',
  headingLevel: 'exclude'
};

Expander.themes = themes;


export default Expander;
