import React from "react";
import PropTypes from "prop-types";

import cn from 'classnames';

import RichText from 'components/rich-text';
import Heading from 'components/heading';

const themes = {
    green: 'green',
    yellow: 'yellow',
    beige: 'beige',
};

const sizes = {
    full: 'full',
    half: 'half'
}

const ArticleInfo = ({
    title,
    content,
    theme,
    size
}) => {

    return (
        <div className={cn('article-info', { [`article-info--${theme} article-info--${size}`]: theme, size })}>
            {title && (
                <Heading
                    level={2}
                    theme={Heading.themes.h3PortalBold}
                    className="article-info__title"
                >
                    {title}
                </Heading>
            )}
            {
                content && (
                    <div className="article-info__text">
                        <RichText
                            themes={[RichText.themes.biggerText]}
                            {...content}
                        />
                    </div>
                )
            }
        </div >
    );
}

ArticleInfo.propTypes = {
    title: PropTypes.string,
    content: PropTypes.exact(RichText.propTypes),
    theme: PropTypes.oneOf(Object.values(themes)),
    size: PropTypes.oneOf(Object.values(sizes))
}

export default ArticleInfo;