export function getUTMParameters() {
  if (typeof window === 'undefined') return;

  let urlParams = new URLSearchParams(window.location.search);

  let UTMParams = {
    UTMSource: urlParams.get('utm_source'),
    UTMMedium: urlParams.get('utm_medium'),
    UTMCampaign: urlParams.get('utm_campaign'),
    UTMTerm: urlParams.get('utm_term'),
    UTMContent: urlParams.get('utm_content')
  };

  return UTMParams;
}
