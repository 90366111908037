import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { MenuContextProvider } from 'contexts/menu-context';

import Footer from 'components/footer';
import Header from 'components/header';
import Support from 'components/support';
import NewsModal from 'components/news-modal';
import GlobalWarningBlock from 'components/global-warning-block';
import SkipNavLink from 'components/skip-nav-link';

const USBL_MAIN_CONTENT_ID = 'usbl-main-content';

const themes = {
  noMinHeight: 'no-min-height',
  housingProject: 'housing-project'
};

const Layout = ({
  skipToMainContentLinkText,
  children,
  footer,
  fullWidthChildren,
  header,
  support,
  theme,
  newsModal
}) => {
  const [warningBlockIsOpen, setWarningBlockIsOpen] = useState(false);
  const [isClient, setIsClient] = useState(false); // Flag for client-side rendering

  // Set the isClient flag to true after the component mounts
  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <React.Fragment>
      <MenuContextProvider>
        <SkipNavLink
          text={skipToMainContentLinkText}
          targetId={USBL_MAIN_CONTENT_ID}
        />
        {header?.globalWarningBlock && (
          <GlobalWarningBlock
            {...header?.globalWarningBlock}
            isOpen={warningBlockIsOpen}
            handleIsOpen={setWarningBlockIsOpen}
            isPlaceholder={true}
          />
        )}
        {header && (
          <Header
            {...header}
            handleChangeWarningBlock={setWarningBlockIsOpen}
            globalWarningBlockIsOpen={warningBlockIsOpen}
          />
        )}
        {(support || children) && (
          <main
            id={USBL_MAIN_CONTENT_ID}
            className={cn('layout', { [`layout--${theme}`]: theme })}
          >
            {support && <Support {...support} />}
            {fullWidthChildren}
            <div className="layout__content">{children}</div>
          </main>
        )}
        {newsModal && <NewsModal {...newsModal} />}
        {footer && <Footer {...footer} theme={theme} />}
      </MenuContextProvider>
    </React.Fragment>
  );
};

Layout.propTypes = {
  skipToMainContentLinkText: PropTypes.string,
  children: PropTypes.node,
  footer: PropTypes.exact(Footer.propTypes),
  fullWidthChildren: PropTypes.node,
  header: PropTypes.exact(Header.propTypes),
  headerTheme: PropTypes.any,
  support: PropTypes.exact(Support.propTypes),
  theme: PropTypes.oneOf(Object.values(themes)),
  newsModal: PropTypes.exact(NewsModal.propTypes)
};

Layout.propTypesMeta = {
  headerTheme: 'exclude',
  theme: 'exclude'
};

Layout.themes = themes;

export default Layout;
