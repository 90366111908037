import React from "react";
import PropTypes from "prop-types";

import cn from 'classnames';

import RichText from 'components/rich-text';
import Image from 'components/image'

const themes = {
    green: 'green',
    yellow: 'yellow',
    beige: 'beige',
};

const sizes = {
    full: 'full',
    half: 'half'
}

const Quote = ({
    author,
    quoteText,
    headshot,
    theme,
    size
}) => {
    return (
        <blockquote className={cn('quote', { [`quote--${theme}`]: theme, [`quote--${size}`]: size })}>
            {quoteText &&
                <div className="quote__text">
                    <RichText
                        themes={[RichText.themes.biggerText]}
                        {...quoteText}
                    />
                </div>
            }
            {author &&
                <p className="quote__author">
                    {author}
                </p>
            }
            {headshot &&
                <div className="quote__headshot">
                    <Image {...headshot} />
                </div>
            }
        </blockquote>
    );
}

Quote.propTypes = {
    author: PropTypes.string,
    quoteText: PropTypes.exact(RichText.propTypes),
    headshot: PropTypes.exact(Image.propTypes),
    theme: PropTypes.oneOf(Object.values(themes)),
    size: PropTypes.oneOf(Object.values(sizes))
}

export default Quote;